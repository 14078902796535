import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style'

import TourHistory from '../tour-history'
import ContactDetails from '../contact-details'
import MoreInfo from '../more-info'
import { useLayout } from '../../../lib/layoutContext'
import RichText from '../../../lib/prismic/richtext'
import { useGlobalState } from '../../../state'
import moment from 'moment'
import { useRouter } from 'next/router'

function FormDialog(props) {
  const layout = useLayout()
  const router = useRouter()
  const { classes } = props
  const [open, setOpen] = useGlobalState('openDialog')
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleNext = () => {
    let contact1 = {
      function: value.function,
      history: value.history,
      pastAgency: value.pastAgency,
      name: value.name,
      organization: value.organization,
      email: value.email,
      countryName: value.countryName,
      countryPhoneCode: value.countryPhoneCode,
      phone: value.phone,
      timezone: value.timezone,
      hearedFrom: value.hearedFrom,
      tripType: value.tripType,
      fromDate: value.fromDate,
      toDate: value.fromDate,
    }

    localStorage.setItem('contact1', JSON.stringify(contact1))
    setStep(step + 1)
  }

  const [step, setStep] = useState(1)

  const handleBack = () => {
    setStep(step - 1)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseError = () => {
    setOpen(false)
    setError(false)
  }

  const [value, setValue] = useState({
    function: '',
    history: '',
    pastAgency: '',
    name: '',
    organization: '',
    email: '',
    emailValid: false,
    countryPhoneCode: null,
    phone: '',
    timezone: '',
    countryName: null,
    hearedFrom: '',
    tripType: '',
    fromDate: null,
    toDate: null,
  })

  const handleChange = (input) => (event) => {
    if (input == 'countryName') {
      return setValue({
        ...value,
        [input]: event,
        countryPhoneCode: event,
      })
    } else if (input == 'countryPhoneCode') {
      return setValue({
        ...value,
        [input]: event,
      })
    } else if (input == 'timezone') {
      return setValue({
        ...value,
        [input]: event,
      })
    } else if (input == 'email') {
      return setValue({
        ...value,
        [input]: event.target.value,
        emailValid: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(event.target.value),
      })
    } else if (input == 'fromDate') {
      return setValue({
        ...value,
        fromDate: event.toString(),
        toDate: new Date(event.setDate(event.getDate() + 1)).toString(),
      })
    } else if (input == 'toDate') {
      return setValue({
        ...value,
        [input]: event.toString(),
      })
    }

    setValue({ ...value, [input]: event.target.value })
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const countryName = value.countryName && value.countryName.label
    const countryCode =
      (value.countryPhoneCode && '+' + value.countryPhoneCode.phone) || ''
    const timezone = value.timezone && value.timezone.label
    let phoneNumber = countryCode + value.phone

    const sendMail = await fetch('/api/send-mail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: value.name,
        email: value.email,
        organization: value.organization,
        before: value.history,
        phone: phoneNumber,
        countryName: countryName,
        timezone: timezone,
        iam: value.function,
        pastAgency: value.pastAgency,
        heared: value.hearedFrom,
        type: value.tripType,
        fromDate:
          value.fromDate && moment(value.fromDate).format('D MMMM YYYY'),
        toDate: value.toDate && moment(value.toDate).format('D MMMM YYYY'),
      }),
    })
    const response = await sendMail
    if (response.status == 200) {
      setStep(step + 1)
    } else {
      handleClose()
      setError(true)
      console.log('We got an error sending the contact form', response)
    }

    const zohoCRM = await fetch('/api/zoho', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: value.name,
        email: value.email,
        organization: value.organization,
        before: value.history,
        phone: phoneNumber,
        countryName: countryName,
        timezone: timezone,
        iam: value.function,
        pastAgency: value.pastAgency,
        heared: value.hearedFrom,
        type: value.tripType,
        fromDate:
          value.fromDate && moment(value.fromDate).format('D MMMM YYYY'),
        toDate: value.toDate && moment(value.toDate).format('D MMMM YYYY'),
      }),
    })
    const zohoResponse = zohoCRM
    if (zohoResponse.status !== 200) {
      handleClose()
      setError(true)
      console.log(
        'We got an error sending the contact form to Zoho',
        zohoResponse
      )
    }
  }

  let formStep
  switch (step) {
    case 1:
      formStep = <TourHistory handleChange={handleChange} values={value} />
      break
    case 2:
      formStep = <ContactDetails handleChange={handleChange} values={value} />
      break
    case 3:
      formStep = <MoreInfo handleChange={handleChange} values={value} />
      break
    case 4:
      {
        router.push('/thank-you', '/thank-you')
        handleClose()
        setStep(1)
      }
      break
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={error}
        onBackdropClick={handleCloseError}
        aria-labelledby='for-dialog-title'
      >
        <DialogContent>
          <h1 className={classes.title}>
            {RichText.asText(layout.error_message_title)}
          </h1>
          <p className={classes.description}>
            {RichText.render(layout.error_message)}
          </p>
        </DialogContent>

        <div className={classes.divider} />
        <DialogActions>
          <Button onClick={handleCloseError} variant='contained'>
            {layout.close_button}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='sm'
        open={open}
        aria-labelledby='form-dialog-title'
      >
        {formStep}
        <DialogActions>
          {step < 4 && (
            <Button onClick={handleClose}>
              {RichText.asText(layout.cancel_button)}
            </Button>
          )}
          <div className={classes.divider} />
          {step > 1 && step < 4 ? (
            <Button onClick={handleBack}>
              {RichText.asText(layout.back_button)}
            </Button>
          ) : null}
          {step < 3 && (
            <Button
              onClick={handleNext}
              variant='contained'
              disabled={!value.emailValid && step === 2}
            >
              {RichText.asText(layout.next_button)}
            </Button>
          )}
          {step == 3 && (
            <Button
              onClick={handleSubmit}
              disabled={submitting}
              variant='contained'
            >
              {RichText.asText(layout.submit_button)}
            </Button>
          )}
          {step > 3 && (
            <Button onClick={handleClose} variant='contained'>
              {RichText.asText(layout.success_button_text)}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(FormDialog)
