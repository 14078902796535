export default ({ theme }) => ({
  MuiCssBaseline: {
    '@global': {
      h1: {
        fontSize: 36,
        fontFamily: 'Merriweather,sans-serif',
      },
      h2: {
        fontSize: 24,
        fontFamily: 'Merriweather,sans-serif',
      },
      h3: {
        fontSize: 28,
      },
      h4: {
        fontSize: 18,
        fontFamily: 'Merriweather,sans-serif',
      },
      h5: {
        textTransform: 'uppercase',
      },
      h6: {
        textTransform: 'uppercase',
      },
      a: {
        color: theme.palette.primary[500],
        textDecoration: 'none',
      },
      strong: {
        fontWeight: 600,
      },
      body: {
        backgroundColor: '#fff',
        '-webkit-font-smoothing': 'antialiased',
      },
      p: {
        //fontSize:"1.3rem"
      },
      strong: {
        fontWeight: 700,
      },
    },
  },
})
