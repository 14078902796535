const styles = (theme) => ({
  toolbarSpacer: {
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'fixed',
    transition: 'all 0.5s ease',
  },
  headerWhite: {
    backgroundColor: '#fff',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  logoLink: {
    display: 'block',
    lineHeight: 0,
  },
  logo: {
    width: 190,
    top: 15,
    transition: 'all 0.5s ease',
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
  },
  logoBlackLetters: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
  },
  link: {
    padding: '14px 15px',
    margin: '0px 0px',
    color: 'white',
    fontWeight: 200,
    textDecoration: 'none',
    fontSize: 15,
    textTransform: 'uppercase',
    display: 'inline-block',
  },
  activeLink: {
    borderBottom: '2px solid ' + theme.palette.primary[500],
  },
  linkBlack: {
    color: '#000',
  },
  iconButton: {
    color: '#fff',
  },
  iconButtonBlack: {
    color: '#000',
  },
  contactButton: {},
  button: {
    marginLeft: 20,
  },
  menu: {},
})

export default styles
