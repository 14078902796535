const styles = (theme) => ({
  outer: {
    padding: theme.blockSpacing,
    textAlign: 'center',
    backgroundColor: theme.palette.primary[600],
  },
  black: {
    color: theme.palette.softBlack.secondary,
    borderColor: theme.palette.softBlack.secondary,
    '&:hover': {
      borderColor: theme.palette.softBlack.primary,
      backgroundColor: theme.palette.softBlack.hint,
    },
  },
  title: {
    margin: 0,
    marginBottom: 20,
    fontSize: 30,
    fontWeight: 700,
    color: 'white',
  },
  inputWrapper: {
    '& .MuiFormLabel-root': {
      color: 'white',
    },
  },
  textField: {
    width: '100%',
    boxShadow: 'none',
    outline: 'none',
    border: 'none',
    fontSize: 50,
    color: 'white',
  },
  input: {
    color: 'white',
    '&:before': {
      borderBottom: '1px solid white',
    },
    '&:after': {
      borderBottom: '2px solid #424F5F',
    },
    '&>input': {
      '&::placeholder': {
        color: 'white',
        fontWeight: 400,
      },
    },
  },
  subscribeButton: {
    marginLeft: 20,
    backgroundColor: 'white',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[400],
    },
  },
})

export default styles
