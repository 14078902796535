import 'lightbox-react/style.css' // This only needs to be imported once in your app
import '../styles/main.scss'
import React from 'react'
import Router from 'next/router'
import NProgress from 'nprogress'
import Head from 'next/head'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { theme } from '../theme'
import { ApolloProvider } from '@apollo/client'
import Header from '../components/header'
import Footer from '../components/footer'
import FormDialog from '../components/form-dialog/main-form'
import NewsletterSignup from '../components/newsletter-signup'
import Sidebar from '../components/sidebar'
import layoutQuery from '../lib/layoutQuery'
import getLanguage from 'lib/getLanguage'
import { LayoutProvider } from '../lib/layoutContext'
import { LanguageProvider } from '../lib/languageContext'
import { GlobalStateProvider } from '../state'
import { initializeApollo, useApollo } from '../services/sarel-apollo'
import { initGoogleTagManager } from '../services/analytics-service'
import ReactDOM from 'react-dom'
import SkipBlock from '../components/skip-block'

Router.events.on('routeChangeStart', (url) => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const SarElApp = ({ Component, pageProps, layout, language }) => {
  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const axe = require('@axe-core/react')
      axe(React, ReactDOM, 1000)
    }
  }, [])

  React.useEffect(initGoogleTagManager, [])

  return (
    <React.Fragment>
      <Head>
        <title>Sar El</title>
        <script
          src={`https://www.googleoptimize.com/optimize.js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
        ></script>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
        />
      </Head>
      <ThemeProvider theme={theme()}>
        <CssBaseline />
        <GlobalStateProvider>
          <ApolloProvider client={useApollo(pageProps)}>
            <LanguageProvider value={language}>
              <LayoutProvider value={layout[0].node}>
                <SkipBlock layout={layout[0].node} />
                <Header layout={layout[0].node} />
                <main id='main'>
                  <Component {...pageProps} layout={layout[0].node} />
                  <NewsletterSignup layout={layout[0].node} />
                </main>
                <Footer layout={layout[0].node} language={language} />
                <Sidebar />
                <FormDialog />
              </LayoutProvider>
            </LanguageProvider>
          </ApolloProvider>
        </GlobalStateProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

SarElApp.getInitialProps = async ({ Component, router, ctx }) => {
  const apolloClient = initializeApollo()

  const { locale } = router // Will return `fr` for `/fr/*` pages

  //todo - router
  const language = getLanguage(locale)

  let pageProps = {}

  const layoutResult = apolloClient.query({
    query: layoutQuery,
    variables: {
      lang: language.prismicLanguage,
    },
  })

  const results = await Promise.all([layoutResult])

  const layout = results[0].data.allLayouts.edges

  if (Component.getInitialProps) {
    ctx.language = language
    pageProps = await Component.getInitialProps(ctx)
  }

  return { pageProps, layout, language }
}

export default SarElApp
