import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DialogContent, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style.js'
import { countries } from './countries.js'
import { timezones } from './timezones.js'
import { useLayout } from '../../../lib/layoutContext'
import RichText from '../../../lib/prismic/richtext'

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

function ContactDetails(props) {
  const { classes, values } = props
  const layout = useLayout()

  return (
    <div>
      <DialogContent>
        <h1 className={classes.title}>
          {RichText.asText(layout.contact_details_title)}
        </h1>
        <p className={classes.description}>
          {RichText.asText(layout.contact_details_caption)}
        </p>
        <TextField
          id='contact-details-name'
          className={classes.textField}
          label={RichText.asText(layout.name)}
          margin='dense'
          type='text'
          value={values.name}
          onChange={props.handleChange('name')}
        />
        <TextField
          id='contact-details-organization'
          className={classes.textField}
          label={RichText.asText(layout.organization)}
          margin='dense'
          type='text'
          value={values.organization}
          onChange={props.handleChange('organization')}
        />
        <TextField
          id='contact-details-email'
          error={!values.emailValid && values.email !== ''}
          className={classes.textField}
          label={RichText.asText(layout.email)}
          margin='dense'
          type='text'
          value={values.email}
          onChange={props.handleChange('email')}
        />
        <Autocomplete
          id='contact-details-countryName'
          options={countries}
          classes={{
            option: classes.option,
          }}
          value={values.countryName}
          autoHighlight
          onChange={(e, v) => {
            props.handleChange('countryName')(v || '')
          }}
          getOptionLabel={(option) => {
            return option.label
          }}
          renderOption={(option) => (
            <React.Fragment>
              <span>{countryToFlag(option.code)}</span>
              {option.label} ({option.code})
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={RichText.asText(layout.country)}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Grid container alignItems='center'>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id='contact-details-countryPhoneCode'
              options={countries}
              value={values.countryPhoneCode}
              className={classes.countryPhoneCode}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              onChange={(e, v) =>
                props.handleChange('countryPhoneCode')(v || '')
              }
              getOptionLabel={(option) => {
                return '+' + option.phone
              }}
              renderOption={(option) => (
                <React.Fragment>
                  <span>{countryToFlag(option.code)}</span>
                  {option.label} ({option.code}) +{option.phone}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={RichText.asText(layout.country_code)}
                  fullWidth
                  className={classes.countryInput}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'disabled', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={true}>
            <TextField
              id='contact-details-phone'
              className={classes.horizontral}
              label={RichText.asText(layout.phone_number)}
              fullWidth
              margin='dense'
              type='text'
              value={values.phone}
              onChange={props.handleChange('phone')}
            />
          </Grid>
        </Grid>
        <Autocomplete
          id='contact-details-timezone'
          options={timezones}
          classes={{
            option: classes.option,
          }}
          value={values.timezone}
          autoHighlight
          onChange={(e, v) => {
            props.handleChange('timezone')(v || '')
          }}
          getOptionLabel={(option) => {
            return option.label
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={layout.timezone}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </DialogContent>
    </div>
  )
}
ContactDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default withStyles(style)(ContactDetails)
