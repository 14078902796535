import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import style from './style'
import { RichText } from 'lib/prismic'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'

class NewsLetterSignup extends React.Component {
  state = {
    newsletterEmail: '',
    newsletterName: '',
    loading: false,
    openSnackbar: false,
    message: '',
    submitting: false,
  }

  onEmailChange = (event) => {
    this.setState({ newsletterEmail: event.target.value })
  }

  onNameChange = (event) => {
    this.setState({ newsletterName: event.target.value })
  }

  componentDidMount() {
    this.setState({ submitting: false })
  }

  subscribe = async () => {
    this.setState({ submitting: true })
    const zohoCRM = await fetch('/api/zoho', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: this.state.newsletterName,
        email: this.state.newsletterEmail,
      }),
    })
    const zohoResponse = zohoCRM
    if (zohoResponse.status !== 200) {
      handleClose()
      setError(true)
      console.log(
        'We got an error sending the contact form to Zoho',
        zohoResponse
      )
    }

    // verifying name and email before sending request to getgist
    if (
      !this.state.newsletterEmail.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState({
        openSnackbar: true,
        message: 'Sorry your email is not valid',
      })

      this.setState({ newsletterName: '', newsletterEmail: '' })
      return
    } else if (this.state.newsletterName.length < 4) {
      this.setState({
        openSnackbar: true,
        message: 'Sorry your name is not valid',
      })

      this.setState({ newsletterName: '', newsletterEmail: '' })
      return
    }
    const rawResponse = await fetch('/api/subscribe-to-newsletter', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.newsletterEmail,
        name: this.state.newsletterName,
      }),
    })
    const content = await rawResponse.json()

    if (content.status !== 200) {
      this.setState({
        openSnackbar: true,
        message: 'One of the fields is not valid',
        newsletterName: '',
        newsletterEmail: '',
      })
    } else {
      this.setState({
        openSnackbar: true,
        message: 'You have successfully subscribed!',
        newsletterName: '',
        newsletterEmail: '',
      })
    }
    return
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ openSnackbar: false })
  }

  render() {
    const { layout, classes } = this.props

    const handleChange = (name) => (event) => {
      setValues({ ...values, [name]: event.target.value })
    }

    return (
      <form className={classes.outer}>
        <Container>
          <Grid container alignItems='center' justify='center' spacing={5}>
            <Grid item xs={12}>
              <h2 className={classes.title}>
                {RichText.asText(layout.newsletter_signup_title)}
              </h2>
            </Grid>

            <Grid item xs={10} sm={6} md={3} className={classes.inputWrapper}>
              <TextField
                id='newsletter-signup-name'
                required
                className={classes.textField}
                inputProps={{
                  'aria-required': 'true',
                }}
                InputProps={{
                  className: classes.input,
                }}
                type='name'
                value={this.state.newsletterName}
                onChange={this.onNameChange}
                label={RichText.asText(
                  layout.newsletter_signup_name_placeholder
                )}
                margin='normal'
              />
            </Grid>

            <Grid item xs={10} sm={6} md={3} className={classes.inputWrapper}>
              <TextField
                id='newsletter-signup-email'
                required
                aria-required='true'
                className={classes.textField}
                inputProps={{
                  'aria-required': 'true',
                }}
                InputProps={{
                  className: classes.input,
                }}
                type='email'
                value={this.state.newsletterEmail}
                onChange={this.onEmailChange}
                color='warning'
                label={RichText.asText(
                  layout.newsletter_signup_email_placeholder
                )}
                margin='normal'
              />
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                onClick={this.subscribe}
                disabled={this.state.submitting}
                className={classes.subscribeButton}
              >
                {RichText.asText(layout.newsletter_signup_button_text)}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.openSnackbar}
          autoHideDuration={3000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id='message-id'>{this.state.message}</span>}
          action={[
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </form>
    )
  }
}

NewsLetterSignup.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(NewsLetterSignup)
