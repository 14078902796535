import { createStyles } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    alignDialog: {
      textAlign: 'center',
    },
    radio: {
      float: 'left',
    },
    formControl: {
      textAlign: 'left',
    },
    textField: { width: '100%' },
    divider: {
      flex: '1 0 0',
    },
  })

export default styles
