const styles = (theme) => ({
  title: {
    marginTop: 0,
    color: '#000',
    fontSize: 28,
    marginBottom: 0,
  },
  description: {
    color: '#000',
    fontSize: 17,
    marginTop: 10,
  },
  alignDialog: {
    textAlign: 'center',
  },
  radio: {},
  formControl: {
    textAlign: 'left',
  },
  textField: {
    width: '100%',
  },
  countryInput: {
    marginTop: 2,
  },
  countryPhoneCode: {
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  /*
  countryInput::before:{
    content: "+"
  }*/
})

export default styles
