import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import {
  MenuItem,
  DialogContent,
  FormControl,
  Select,
  InputLabel,
  FormLabel,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style.js'
import DateFnsUtils from '@date-io/date-fns'
import RichText from '../../../lib/prismic/richtext'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { useLayout } from '../../../lib/layoutContext'
function MoreInfo(props) {
  const { classes, values, handleChange } = props
  const [value, setValue] = useState({
    function: '',
    history: '',
    pastAgency: '',
  })
  const [selectedFromDate, setSelectedFromDate] = useState(null)
  const [selectedToDate, setSelectedToDate] = useState(null)
  const layout = useLayout()
  const handleFromDateChange = (date) => {
    setSelectedFromDate(date)
    handleChange('fromDate')(new Date(date))
  }
  const handleToDateChange = (date) => {
    setSelectedToDate(date)
    handleChange('toDate')(date)
  }
  const tripTypes = layout.type_of_trip.map((type, i) => {
    return (
      <MenuItem value={type.type[0].text} key={i}>
        {RichText.asText(type.type)}
      </MenuItem>
    )
  })
  return (
    <div>
      <DialogContent>
        <h1 className={classes.title}>
          {RichText.asText(layout.more_info_title)}
        </h1>
        <p className={classes.description}>
          {RichText.asText(layout.more_info_caption)}
        </p>
        <TextField
          id='more-info-heared-from'
          className={classes.textField}
          label={RichText.asText(layout.heard_about)}
          margin='dense'
          fullWidth
          value={values.hearedFrom}
          onChange={props.handleChange('hearedFrom')}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id='more-info-trip-type-label' value=''>
            {RichText.asText(layout.trip_type)}
          </InputLabel>
          <Select
            labelId='more-info-trip-type-label'
            id='more-info-trip-type'
            className={classes.select}
            fullWidth
            onChange={props.handleChange('tripType')}
          >
            {tripTypes}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <FormLabel component='legend' className={classes.formLabel}>
            {RichText.asText(layout.dates_title)}
          </FormLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <label className={classes.calenderLabel}>
              {RichText.asText(layout.from)}
            </label>
            <DatePicker
              className={classes.datePicker}
              clearable
              emptyLabel={RichText.asText(layout.no_date_picked)}
              value={values.fromDate}
              placeholder='10/10/2018'
              onChange={(date) => handleFromDateChange(date)}
              minDate={new Date()}
              format='MM/dd/yyyy'
            />
            <label className={classes.calenderLabel}>
              {RichText.asText(layout.to)}
            </label>
            <DatePicker
              className={classes.datePicker}
              clearable
              emptyLabel={RichText.asText(layout.no_date_picked)}
              value={values.toDate}
              onChange={(date) => handleToDateChange(date)}
              minDate={new Date()}
              format='MM/dd/yyyy'
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </DialogContent>
    </div>
  )
}
MoreInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default withStyles(style)(MoreInfo)
