function linkResolver(doc, language) {
  let url = ''

  if (doc.type === 'page') url = `/${doc.uid}`
  else if (doc.type === 'article') url = `/article/${doc.uid}`
  else url = '/'

  if (language) {
    return `/${language.language}${url}`
  } else {
    return url
  }
}

module.exports = {
  linkResolver,
}
