import React from 'react'
import TextField from '@material-ui/core/TextField'
import {
  DialogContent,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Grid,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import RichText from '../../../lib/prismic/richtext'
import PropTypes from 'prop-types'
import style from './style.js'
import { useLayout } from '../../../lib/layoutContext'

function TourHistory(props) {
  const { classes, values } = props

  const layout = useLayout()
  const clientTypes = layout.client_type_options.map((type, i) => {
    return (
      <Grid item xs={12} key={i}>
        <FormControlLabel
          className={classes.radio}
          value={type.type_option[0].text}
          control={<Radio color='primary' />}
          label={RichText.asText(type.type_option)}
        />
      </Grid>
    )
  })

  return (
    <div>
      <DialogContent>
        <h1 className={classes.title}>
          {RichText.asText(layout.first_step_title)}
        </h1>
        <p className={classes.description}>
          {RichText.asText(layout.first_step_caption)}
        </p>
        <FormControl component='fieldset' className={classes.formControl}>
          <FormLabel component='legend' className={classes.formLabel}>
            {RichText.asText(layout.client_type)}
          </FormLabel>
          <RadioGroup
            row
            name='function'
            value={values.function}
            onChange={props.handleChange('function')}
          >
            <Grid container>{clientTypes}</Grid>
          </RadioGroup>
        </FormControl>
        <FormControl component='fieldset' className={classes.formControl}>
          <FormLabel component='legend' className={classes.formLabel}>
            {RichText.asText(layout.travel_history_text)}
          </FormLabel>
          <RadioGroup
            row
            name='travelHistory'
            value={values.history}
            onChange={props.handleChange('history')}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.radio}
                  value='yes'
                  control={<Radio color='primary' />}
                  label={RichText.asText(layout.traveled_before_yes)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.radio}
                  value='no'
                  control={<Radio color='primary' />}
                  label={RichText.asText(layout.traveled_before_no)}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
        {values.history == 'yes' && (
          <div>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend' className={classes.formLabel}>
                {RichText.asText(layout.what_agency)}
              </FormLabel>
              <TextField
                className={classes.textField}
                value={values.pastAgency}
                onChange={props.handleChange('pastAgency')}
              />
            </FormControl>
          </div>
        )}
      </DialogContent>
    </div>
  )
}
TourHistory.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default withStyles(style)(TourHistory)
