const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '4px',
  },
  drawer: {
    padding: '10px 20px',
    width: 230,
  },
  link: {
    width: '100%',
    display: 'block',
    padding: '8px 0px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#666',
    fontWeight: 700,
  },
  links: {
    marginBottom: 10,
  },
})

export default styles
