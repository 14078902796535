const styles = (theme) => ({
  title: {
    marginTop: 0,
    color: '#000',
    fontSize: 28,
    marginBottom: 0,
  },
  description: {
    color: '#000',
    fontSize: 17,
    marginTop: 10,
  },
  alignDialog: {
    textAlign: 'center',
  },
  select: {
    width: '100%',
  },
  datePicker: {
    marginTop: 30,
  },
  formLabel: {
    fontWeight: 600,
    color: '#000 !important',
    marginTop: 40,
  },
  formControl: {
    textAlign: 'left',
    width: '100%',
  },
  textField: {
    width: '100%',
    marginBottom: 10,
  },
  calenderLabel: {
    marginBottom: -27,
    marginTop: 15,
  },
})

export default styles
