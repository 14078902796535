import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import style from './style.js'
import clsx from 'clsx'
import RichText from 'lib/prismic/richtext'
import Link from 'next/link'
import ContactButton from '../_ui/contact-button/button'
import { useGlobalState } from '../../state'
import { useRouter } from 'next/router'

function Header(props) {
  const { classes, layout } = props
  const [state, setState] = useState({
    prevScrollpos: 0,
    headerWhite: false,
  })

  const [sidebar, setSidebar] = useGlobalState('sidebar')

  const router = useRouter()

  const items = layout.menu_items

  const slug = (router && router.query && router.query.slug) || ''

  const renderMenuItems = (menuItems) => {
    return menuItems
      .filter((x) => x.link)
      .map((menuItem, index) => {
        const isActiveLink = menuItem.link._meta.uid === slug

        return (
          <Link
            key={index}
            href={`/index?slug=${menuItem.link._meta.uid}`}
            as={
              menuItem.link._meta.uid === 'homepage'
                ? '/'
                : `/${menuItem.link._meta.uid}`
            }
            passHref
          >
            <a
              className={clsx(classes.link, {
                [classes.linkBlack]: state.headerWhite,
                [classes.activeLink]: isActiveLink,
              })}
            >
              {RichText.asText(menuItem.name)}
            </a>
          </Link>
        )
      })
  }

  const handleScroll = () => {
    const { prevScrollpos } = state

    const currentScrollPos = window.pageYOffset
    const headerWhite = currentScrollPos > 100

    setState({
      prevScrollpos: currentScrollPos,
      headerWhite,
    })
  }

  const openSidebar = () => {
    setSidebar(true)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <div>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.headerWhite]: state.headerWhite,
        })}
      >
        <Toolbar disableGutters={false} className={classes.toolbar}>
          <Container>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
            >
              <Grid item>
                <Link
                  href={`/index?slug=${layout.menu_items[0].link._meta.uid}`}
                  as={
                    layout.menu_items[0].link._meta.uid === 'homepage'
                      ? '/'
                      : `/${layout.menu_items[0].link._meta.uid}`
                  }
                  passHref
                >
                  <a
                    className={classes.logoLink}
                    aria-label={RichText.asText(layout.menu_items[0].name)}
                  >
                    <img
                      src={
                        state.headerWhite
                          ? layout.logo_black_letters.url
                          : layout.logo.url
                      }
                      alt={
                        state.headerWhite
                          ? layout.logo_black_letters.alt
                          : layout.logo.alt
                      }
                      className={clsx(classes.logo, {
                        [classes.logoBlackLetters]: state.headerWhite,
                      })}
                    />
                  </a>
                </Link>
              </Grid>
              <Hidden smDown>
                <Grid
                  item
                  container
                  xs={true}
                  alignItems='center'
                  justify='flex-end'
                >
                  <Grid item>{renderMenuItems(layout.menu_items)}</Grid>
                  <Grid item>
                    <div className={classes.button}>
                      <ContactButton variant='contained'>
                        {RichText.asText(layout.contact_us_button)}
                      </ContactButton>
                    </div>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  onClick={openSidebar}
                  aria-label={layout.menu_open_label}
                  className={
                    state.headerWhite
                      ? classes.iconButtonBlack
                      : classes.iconButton
                  }
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Header)
