import TagManager from 'react-gtm-module'

export const initGoogleTagManager = () => {
  if (typeof window !== 'undefined') {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    })
  }
}
