export default ({ theme }) => ({
  MuiDialog: {
    paper: {
      margin: 0,
    },
    paperFullWidth: {
      width: 'calc(100% - 20px)',
    },
  },
  MuiDialogActions: {
    root: {
      padding: 16,
    },
  },
})
