import React from 'react'
import { RichText } from 'lib/prismic'
import { Elements } from 'prismic-richtext'
import { withStyles } from '@material-ui/core/styles'
import style from './style.js'
import { Link } from '@material-ui/core'

const SkipBlock = ({ layout, classes }) => {
  function propsWithUniqueKey(props, key) {
    return Object.assign(props || {}, { key })
  }

  function serializeStandardTag(tag, element, children, key) {
    const props = element.label
      ? Object.assign({}, { className: element.label })
      : {}
    return React.createElement(tag, propsWithUniqueKey(props, key), children)
  }

  function serializeSpan(content) {
    if (content) {
      return content.split('\n').reduce((acc, p) => {
        if (acc.length === 0) {
          return [p]
        } else {
          const brIndex = (acc.length + 1) / 2 - 1
          const br = React.createElement('br', propsWithUniqueKey({}, brIndex))
          return acc.concat([br, p])
        }
      }, [])
    } else {
      return null
    }
  }
  function serializeHyperlink(element, children, key) {
    const targetAttr = element.data.target
      ? { target: element.data.target }
      : {}
    const relAttr = element.data.target ? { rel: 'noopener' } : {}
    const props = Object.assign(
      { href: element.data.url.replace('https://', '') },
      targetAttr,
      relAttr
    )
    return <Link {...propsWithUniqueKey(props, key)}>{children}</Link>
  }

  return (
    <div className={classes.skipBlock}>
      {RichText.render(
        layout.main_skip_to_block_text,
        (type, element, content, children, index) => {
          switch (type) {
            case Elements.paragraph:
              return serializeStandardTag('p', element, children, index)
            case Elements.span:
              return serializeSpan(content)
            case Elements.hyperlink:
              return serializeHyperlink(element, children, index)
            default:
              return null
          }
        }
      )}
    </div>
  )
}

export default withStyles(style)(SkipBlock)
