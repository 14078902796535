const placeholder = {
  color: 'white',
}

const styles = (theme) => ({
  padding: {
    padding: '40px 0px',
  },
  title: {
    color: '#fff',
    marginTop: 15,
  },
  footerContact: {
    '@global': {
      a: {
        color: '#fff',
      },
    },
  },
  footerButton: {
    color: '#fff',
    borderColor: '#fff',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  footer: {
    backgroundColor: 'rgb(16, 39, 65)',
    padding: '20px 0px',
    color: '#fff',
    overflow: 'hidden',
    '@global': {
      p: {
        color: '#fff',
      },
    },
  },
  description: {
    color: '#fff',
    maxWidth: 350,
    marginBottom: 20,
  },
  footerMenuItems: {
    marginTop: 15,
  },
  flag: {
    width: 20,
    marginRight: 10,
  },
  flagButton: {
    width: 20,
  },
  languageMenuButton: {
    color: 'white',
    marginLeft: 10,
  },
  link: {
    color: '#fff',
    fontWeight: 700,
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: 1.95,
    textTransform: 'uppercase',
  },
  icon: {
    fontSize: 25,
    lineHeight: '25px',
    color: '#fff',
  },
  iconLink: {
    marginLeft: 10,
  },
  groupImage: {
    margin: '0 auto',
    width: 150,
  },
  image: {
    width: 150,
    marginBottom: 100,
  },
  wliLinkContainer: {
    textAlign: 'center',
  },
  wliLink: {
    color: theme.palette.primary[400],
    fontSize: 18,
  },
})

export default styles
