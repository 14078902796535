import variables from './variables'

const returnTheme = () => {
  const req = require.context('./components', false, /.js$/)
  let overrides = {}

  const getVariables = variables()

  req.keys().forEach((filename) => {
    overrides = {
      ...overrides,
      ...req(filename).default(getVariables),
    }
  })

  return {
    ...getVariables.theme,
    overrides,
  }
}

export default returnTheme
