import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import style from './style.js'
import { RichText } from 'lib/prismic'
import Drawer from '@material-ui/core/Drawer'
import Link from 'next/link'
import { useLayout } from '../../lib/layoutContext'
import ContactButton from '../_ui/contact-button/button'
import { useGlobalState } from '../../state'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'

function Sidebar(props) {
  const { classes } = props
  const [sidebar, setSidebar] = useGlobalState('sidebar')
  const layout = useLayout()

  const closeSidebar = () => {
    setSidebar(false)
  }

  const renderMenuItems = () => {
    return layout.menu_items.map((menuItem, index) => {
      return (
        <Link
          key={index}
          href={`/index?slug=${menuItem.link._meta.uid}`}
          as={`/${menuItem.link._meta.uid}`}
          passHref
        >
          <a className={classes.link} onClick={closeSidebar}>
            {RichText.asText(menuItem.name)}
          </a>
        </Link>
      )
    })
  }

  return (
    <Drawer open={sidebar} anchor='right' onClose={closeSidebar}>
      <div className={classes.container}>
        <div className={classes.drawer}>
          <div className={classes.links}>{renderMenuItems()}</div>
          <div onClick={closeSidebar}>
            <ContactButton variant='contained'>
              {RichText.asText(layout.contact_us_button)}
            </ContactButton>
          </div>
        </div>
        <IconButton aria-label={layout.menu_close_label} onClick={closeSidebar}>
          <CloseIcon />
        </IconButton>
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Sidebar)
