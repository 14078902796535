export default ({ theme }) => {
  return {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
      contained: {
        backgroundColor: theme.palette.primary[600],
        color: 'white',
        '&:hover': {
          backgroundColor: theme.palette.primary[700],
        },
      },
      outlined: {
        borderColor: theme.palette.primary[600],
        color: theme.palette.primary[600],
        '&:hover': {
          backgroundColor: theme.palette.primary[100],
          borderColor: theme.palette.primary[500],
        },
      },
    },
  }
}
