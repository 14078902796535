import React, { Component } from 'react'
import Link from 'next/link'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import style from './style.js'
import { RichText } from 'lib/prismic'
import { Button, Menu, MenuItem } from '@material-ui/core'
import clsx from 'clsx'
import { useRouter } from 'next/router'

function Footer(props) {
  const { classes, layout, language } = props

  const router = useRouter()

  const renderMenuItems = (menuItems) => {
    return menuItems
      .filter((x) => x.link)
      .map((menuItem, index) => {
        return (
          <div key={index}>
            <Link
              href={`/index?slug=${menuItem.link._meta.uid}`}
              as={
                menuItem.link._meta.uid === 'homepage'
                  ? '/'
                  : `/${menuItem.link._meta.uid}`
              }
              passHref
            >
              <a className={classes.link}>{menuItem.name}</a>
            </Link>
          </div>
        )
      })
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (language) => {
    setAnchorEl(null)

    router.push('/', '/', { locale: language.language_key })
  }
  const languages = layout.language_menu.map((language, idx) => {
    return (
      <MenuItem key={idx} onClick={() => handleClose(language)}>
        <img
          role='presentation'
          className={classes.flag}
          src={language.language_image.url}
          alt=''
        />
        {RichText.asText(language.language)}
      </MenuItem>
    )
  })

  const currentFlag = layout.language_menu.find((l) => {
    return l.language_key == language.urlKey
  })

  const [anchorEl, setAnchorEl] = React.useState(null)

  const renderSocialMediaButtons = (socialLinks) => {
    return socialLinks.map((link, i) => {
      return (
        <a
          key={i}
          href={link.url.url}
          aria-label={`${link.social_name} (${layout.main_blank_link_label})`}
          target='_blank'
          className={classes.iconLink}
        >
          <i
            role='presentation'
            className={clsx(link.icon_name, classes.icon)}
          />
        </a>
      )
    })
  }

  return (
    <footer id='footer'>
      <div className={classes.footer}>
        <Container>
          <Grid
            container
            className={classes.padding}
            direction='row'
            justify='center'
            alignItems='flex-start'
            spacing={8}
          >
            <Grid item xs={12} md={4}>
              <img src={layout.logo.url} alt={layout.logo.alt || ''} />
              <p className={classes.description}>
                {RichText.asText(layout.footer_about_description)}
              </p>
              <Link
                href={`/index?slug=${layout.footer_about_link._meta.uid}`}
                as={`/${layout.footer_about_link._meta.uid}`}
                passHref
              >
                <Button
                  component='a'
                  variant='outlined'
                  className={classes.footerButton}
                >
                  {RichText.asText(layout.footer_about_button)}
                </Button>
              </Link>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 className={classes.title}>
                {RichText.asText(layout.footer_links_title)}
              </h2>
              <div className={classes.footerMenuItems}>
                {renderMenuItems(layout.footer_links)}
              </div>
              <div>
                <Button
                  aria-controls='simple-menu'
                  color='primary'
                  aria-haspopup='true'
                  onClick={handleClick}
                >
                  <img
                    className={classes.flagButton}
                    src={currentFlag.language_image.url}
                    alt=''
                  />
                  <span className={classes.languageMenuButton}>
                    {language.nativeLanguageName}
                  </span>
                </Button>
                <Menu
                  id='simple-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={(e) => handleClose(e)}
                >
                  {languages}
                </Menu>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <h2 className={classes.title}>
                {RichText.asText(layout.footer_contact_title)}
              </h2>
              <div className={classes.footerContact}>
                {RichText.render(layout.footer_contact_description)}
              </div>
              <Grid container alignItems='center'>
                <Grid item>
                  <b>{RichText.asText(layout.footer_contact_follow_us)}:</b>
                </Grid>
                <Grid item>
                  {renderSocialMediaButtons(layout.footer_contact_social)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.groupImage}>
            <a
              href={layout.sarel_group_link.url}
              target='_blank'
              aria-label={`Sar-El Group (${layout.main_blank_link_label})`}
            >
              <img
                className={classes.image}
                src={layout.sarel_group_image.url}
                alt={layout.sarel_group_image.alt || ''}
              />
            </a>
          </div>
          <div className={classes.wliLinkContainer}>
            <a
              aria-label={layout.main_blank_link_label}
              href={layout.wli_link.url}
              target='_blank'
              className={classes.wliLink}
            >
              {RichText.asText(layout.wli_text)}
            </a>
          </div>
        </Container>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(Footer)
