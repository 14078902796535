import { createMuiTheme } from '@material-ui/core/styles'

const createTheme = () => {
  const primary = {
    50: '#fbf2e4',
    100: '#e7dac9',
    200: '#d4c1ab',
    300: '#c1a98a',
    400: '#af906a',
    500: '#957650',
    600: '#755c3e',
    700: '#53422b',
    800: '#332717',
    900: '#170b00',
    A100: '#ffdcba',
    A200: '#ffc287',
    A400: '#ffa854',
    A700: '#ff9b3b',
  }

  const gray = {
    50: '#fbfbfb',
    100: '#f6f6f6',
    200: '#f0f0f0',
    300: '#eaeaea',
    400: '#e6e6e6',
    500: '#e1e1e1',
    600: '#dddddd',
    700: '#d9d9d9',
    800: '#d5d5d5',
    900: '#cdcdcd',
    A100: '#ffffff',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ffffff',
  }

  const secondary = {
    50: '#f1f1fb',
    100: '#d6d8de',
    200: '#bbbec3',
    300: '#a2a4aa',
    400: '#878a91',
    500: '#6d7178',
    600: '#54575d',
    700: '#3c3f44',
    800: '#23252b',
    900: '#050e15',
    A100: '#6767F6',
    A200: '#3737F3',
    A400: '#0000F6',
    A700: '#0000DD',
  }

  const themeObject = {
    typography: {
      useNextVariants: true,
      fontSize: 15,
      fontWeightRegular: 500,
      // Use the system font instead of the default Roboto font.
      fontFamily: 'Montserrat,Helvetica,Arial,serif',
    },
    mixins: {
      toolbar: {
        '@media (min-width:0px) and (orientation: landscape)': {
          minHeight: 48,
        },
        '@media (min-width:600px)': {
          minHeight: 80,
        },
        minHeight: 70,
      },
    },
    palette: {
      text: {
        primary: '#424F5F',
        secondary: '#667587',
      },
      primary: primary,
      secondary: {
        ...secondary,
        ...{
          light: secondary[300],
          main: secondary[500],
          dark: secondary[700],
        },
      },
      gray: {
        ...gray,
        ...{
          light: gray[300],
          main: gray[100],
          dark: gray[200],
        },
      },
      softBlack: {
        text: 'black',
        primary: 'rgba(75,78,83,1.0)',
        secondary: 'rgba(75,78,83,0.7)',
        disabled: 'rgba(75,78,83,0.38)',
        hint: 'rgba(75,78,83,0.05)',
      },
      action: {
        selected: '#f5f6f9',
      },
    },
    fonts: {
      lato: 'Lato,Helvetica,Arial,serif;',
      openSans: 'Open Sans,Helvetica,Arial,serif;',
    },
    spacing: 4,
    blockSpacing: '80px 0px',
    props: {},
    shadows: [
      'none',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 1px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
      '0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15)',
    ],
  }

  const theme = createMuiTheme(themeObject)

  const white = {
    text: '#ffffff',
    primary: 'rgba(255, 255, 255, 0.7)',
    secondary: 'rgba(255, 255, 255, 0.54)',
    disabled: 'rgba(255, 255, 255, 0.38)',
    hint: 'rgba(255, 255, 255, 0.24)',
  }

  return {
    theme,
    white,
  }
}

export default createTheme
