import gql from 'graphql-tag'

const layoutQuery = gql`
  query LAYOUT_QUERY($lang: String) {
    allLayouts(lang: $lang) {
      edges {
        node {
          primary_color {
            key
            color
          }
          main_blank_link_label
          main_skip_to_block_text
          main_enlarge_image_label
          contact_us_button
          logo
          logo_black_letters
          footer_about_logo
          footer_about_description
          footer_about_button
          footer_about_link {
            ... on _Document {
              _meta {
                uid
              }
            }
          }
          footer_links_title
          footer_links {
            link {
              ... on _Document {
                _meta {
                  uid
                }
              }
            }
            name
          }
          language_menu {
            language
            language_link {
              ... on _ExternalLink {
                url
              }
            }
            language_image
            language_key
          }
          footer_contact_title
          footer_contact_description
          footer_contact_follow_us
          footer_contact_social {
            social_name
            icon_name
            url {
              _linkType
              ... on _ExternalLink {
                url
              }
            }
          }
          menu_open_label
          menu_close_label
          menu_items {
            name
            link {
              ... on _Document {
                _meta {
                  uid
                }
              }
            }
          }
          newsletter_signup_title
          newsletter_signup_name_placeholder
          newsletter_signup_email_placeholder
          newsletter_signup_button_text
          newsletter_url
          first_step_title
          first_step_caption
          country
          client_type
          email
          country_code
          timezone
          phone_number
          no_date_picked
          from
          to
          client_type_options {
            type_option
          }

          contact_details_title
          contact_details_caption
          name
          organization
          heard_about
          travel_history_text
          traveled_before_yes
          traveled_before_no
          dates_title
          trip_type
          from
          to
          cancel_button
          back_button
          next_button
          submit_button
          what_agency
          type_of_trip {
            type
          }
          more_info_title
          more_info_caption
          sarel_group_link {
            ... on _ExternalLink {
              url
            }
          }
          wli_link {
            ... on _ExternalLink {
              url
            }
          }
          wli_text
          close_button
          error_message
          error_message_title
          error_title
          error_not_found_text
          error_server_text
          error_button_text
          error_background
          sarel_group_image
          sarel_group_image
          success_title
          success_message
          success_button_text
          success_button_link {
            ... on _Document {
              _meta {
                uid
              }
            }
          }
          snackbar_email_error_message
          snackbar_zohocrm_error_message
          snackbar_success_message
          first_name
          required_first_name
          last_name
          simple_email
          invalid_email
          required_email
          phone
          simple_country
          required_country
          role
          render_value_role
          message
        }
      }
    }
  }
`

export default layoutQuery
