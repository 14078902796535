export default ({ theme, white }) => {
  const placeholder = {
    color: theme.palette.text.secondary,
    opacity: 0.7,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  }

  return {
    MuiFormLabel: {
      root: {
        fontSize: '17px',
      },
    },

    MuiInputAdornment: {},

    MuiSelect: {
      icon: {
        marginRight: 8,
      },
      select: {
        '&:focus': {
          backgroundColor: '#fff',
        },
      },
    },
  }
}
