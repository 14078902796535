const styles = (theme) => ({
  title: {
    marginTop: 0,
    color: '#000',
    fontSize: 28,
    marginBottom: 0,
  },
  description: {
    color: '#000',
    fontSize: 17,
    marginTop: 10,
  },
  alignDialog: {},
  radio: {},
  formControl: {
    textAlign: 'left',
    marginTop: 22,
    width: '100%',
  },
  formLabel: {
    fontWeight: 600,
    color: '#000 !important',
    marginBottom: 10,
  },
  textField: {
    width: '100%',
  },
})

export default styles
