export default ({ theme }) => ({
  MuiCard: {
    root: {
      boxShadow: 'none',
    },
  },
  MuiCardContent: {
    root: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  },
})
