import React, { useContext } from 'react'

const LanguageContext = React.createContext({})

export const LanguageProvider = LanguageContext.Provider
export const LanguageConsumer = LanguageContext.Consumer

export const useLanguage = () => {
  return useContext(LanguageContext)
}

export default LanguageContext
