const prismicLanguages = {
  en: {
    prismicLanguage: 'en-us',
    urlKey: 'en',
    nativeLanguageName: 'English',
  },
  fr: {
    prismicLanguage: 'fr-fr',
    urlKey: 'fr',
    nativeLanguageName: 'Français',
  },
  de: {
    prismicLanguage: 'de-de',
    urlKey: 'de',
    nativeLanguageName: 'Deutsch',
  },
  es: {
    prismicLanguage: 'es-es',
    urlKey: 'es',
    nativeLanguageName: 'Español',
  },
  id: {
    prismicLanguage: 'id',
    urlKey: 'id',
    nativeLanguageName: 'bahasa Indonesia',
  },
  it: {
    prismicLanguage: 'it-it',
    urlKey: 'it',
    nativeLanguageName: 'Italiano',
  },
  br: {
    prismicLanguage: 'pt-br',
    nativeLanguageName: 'português do Brasil',
    urlKey: 'br',
  },
  nl: {
    prismicLanguage: 'nl-nl',
    urlKey: 'nl',
    nativeLanguageName: 'Nederlands',
  },
  ru: {
    prismicLanguage: 'ru',
    urlKey: 'ru',
    nativeLanguageName: 'русский язык',
  },
  cn: {
    prismicLanguage: 'zh-cn',
    urlKey: 'cn',
    nativeLanguageName: '汉语',
  },
  hk: {
    prismicLanguage: 'zh-hk',
    urlKey: 'hk',
    nativeLanguageName: '香港',
  },
}

export default function getLanguage(language) {
  const currentLanguage = language ? language : 'en'

  return prismicLanguages.hasOwnProperty(currentLanguage)
    ? prismicLanguages[currentLanguage]
    : {
        nativeLanguageName: 'English',
        prismicLanguage: 'en-us',
        urlKey: 'en',
      }
}
