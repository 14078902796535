import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import style from './style.js'
import { useGlobalState } from '../../../state'

const ContactButton = (props) => {
  const [openDialog, setOpenDialog] = useGlobalState('openDialog')

  const { classes, children } = props

  return (
    <Button {...props} onClick={() => setOpenDialog(true)} variant='contained'>
      {children}
    </Button>
  )
}

ContactButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(style)(ContactButton)
