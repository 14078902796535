const styles = (theme) => ({
  skipBlock: {
    opacity: 0,
    background: '#fff',
    position: 'fixed',
    zIndex: 100000,
    padding: '0px 10px',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)',
    '&:focus-within': {
      opacity: 1,
    },
  },
})

export default styles
