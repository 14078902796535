import React, { useContext } from 'react'

const LayoutContext = React.createContext<{
  next_button: string
  cancel_button: string
  submit_button: string
  back_button: string
  success_button_text: string
  error_message: string
  error_message_title: string
  close_button: string
  snackbar_email_error_message: string
  snackbar_zohocrm_error_message: string
  snackbar_success_message: string
  first_name: string
  required_first_name: string
  last_name: string
  simple_email: string
  invalid_email: string
  required_email: string
  phone: string
  simple_country: string
  required_country: string
  role: string
  render_value_role: string
  message: string
}>({
  next_button: null,
  submit_button: null,
  cancel_button: null,
  back_button: null,
  success_button_text: null,
  error_message: null,
  error_message_title: null,
  close_button: null,
  snackbar_email_error_message: null,
  snackbar_zohocrm_error_message: null,
  snackbar_success_message: null,
  first_name: null,
  required_first_name: null,
  last_name: null,
  simple_email: null,
  invalid_email: null,
  required_email: null,
  phone: null,
  simple_country: null,
  required_country: null,
  role: null,
  render_value_role: null,
  message: null,
})

export const LayoutProvider = LayoutContext.Provider
export const LayoutConsumer = LayoutContext.Consumer

export const useLayout = () => {
  return useContext(LayoutContext)
}

export default LayoutContext
